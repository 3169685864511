import style from './ComingSoon.module.css'

const ComingSoon = () => {

    return (
        <div className={style.comingSoon}>
            
      </div>
    )
}

export default ComingSoon;